import React, { useState } from 'react';
import { Toggle, Button } from 'rsuite';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoCopyOutline } from "react-icons/io5";
import api, {app_address} from "../../http";
import {useTranslation} from "react-i18next";

const PublicGalleryToggle = ({eventId, GalleryPublic}) => {
    const [toggle, setToggle] = useState(GalleryPublic);
    const {t} = useTranslation();
    const [link, setLink] = useState(`${app_address}/gallery?eventId=${eventId}`);

    const handleToggle = () => {
        setToggle(!toggle);

        api.put(`/events/${eventId}/toggle-gallery-privacy`, { gallery_public: !toggle },
            {withCredentials: true})
            .then(response => {
                // If the response contains a link, set it in state
                // if(response.data.link) {
                //     setLink(response.data.link);
                // }
            })
            .catch(err => console.error(err));
    };

    return (
        <div>
            <label>{t('gallery_privacy_toggle_label')} </label>
            <Toggle checkedChildren={t('gallery_privacy_toggle_public')} unCheckedChildren={t('gallery_privacy_toggle_private')} checked={toggle} onChange={handleToggle}>
            </Toggle>

            {toggle && <div>
                <div style={{backgroundColor: 'whitesmoke', display: 'inline-block', borderRadius:'5px', marginTop: '20px'}}>
                <Button href={link} target='_blank' appearance="link">{link}</Button>
                </div>
                <CopyToClipboard text={link}>
                <Button appearance="link" startIcon={<IoCopyOutline />}>{t('copy_btn')}</Button>
                </CopyToClipboard>

            </div>}
        </div>
    );
};

export default PublicGalleryToggle;