import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {api_address, app_address} from "../http";
import {Loader} from "rsuite";

const OAuthRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        if (window.location.hostname === 'localhost' && window.location.port === '3000') {
            // Construct the new URL by replacing the hostname and keeping the pathname and search (query parameters)
            const newUrl = `${app_address}${window.location.pathname}${window.location.search}`;
            // Redirect to the new URL
            window.location.href = newUrl;
        }
        else if (code) {
            // Send the code to your backend
            axios.get(`${api_address}/auth/oauth-redirect?code=${code}`, {withCredentials: true})
                .then(response => {
                    navigate('/');
                })
                .catch(error => {
                    navigate('/login');
                });
        }
    }, [location]); // This effect depends on the location

    return (
        <div style={{display: "flex", justifyContent: 'center', flexDirection: 'column', height: '100vh',
            width: '100%'}}><Loader
            size="lg"/></div>
    );
};

export default OAuthRedirect;
