import api, {api_address} from "../../http";
import React, { useState } from 'react';
import {Uploader, toaster, Notification, Button, Tooltip, Whisper} from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import axios from 'axios';
import {FaFileExcel} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const FileUploadButton = ({eventId, handleDataChanged}) => {
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [key, setKey] = useState(0);
    const handleUpload = (fileList) => {
        if (fileList.length > 0) {
        const file = fileList[fileList.length - 1]; // Get the last selected file
        const validTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv'
        ];

        if (validTypes.includes(file.blobFile.type)) {
            setFile(file.blobFile);
            sendFileToServer(file.blobFile);
        } else {
            toaster.push(
                <Notification type="error" header="Error" closable>
                    Unsupported file type.
                </Notification>,
                { placement: 'topEnd' }
            );
        }
        setKey(prevKey => prevKey + 1);
        };
    };

    const sendFileToServer = (file) => {
        const formData = new FormData();
        formData.append("file", file);

        api.post(`/events/${eventId}/guests/upload_list`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }, withCredentials: true
        })
            .then(() => {
                toaster.push(
                    <Notification type="success" header="Success" closable>
                        File uploaded successfully.
                    </Notification>,
                    { placement: 'topEnd' }
                );
                handleDataChanged();
            })
            .catch((error) => {
                if (error.response) {
                    // Check for status code 402
                    if (error.response.status === 402) {
                        toaster.push(
                            <Notification type="error" header="Error" closable>
                                {t('guests_limit_error')}
                            </Notification>,
                            { placement: 'topEnd' }
                        );
                    }
                } else {
                    toaster.push(
                        <Notification type="error" header="Error" closable>
                            Failed to upload file.
                        </Notification>,
                        { placement: 'topEnd' }
                    );
                }

            });
    };

    return (
        <>
        <Uploader
            key={key}
            multiple={false}
            autoUpload={false}
            onChange={handleUpload}
        >
            <Whisper followCursor speaker={<Tooltip>The file must have "name" and "phone" columns</Tooltip>}>
            <Button startIcon={<FaFileExcel style={{ color: '#10793F' }} />}>{t('upload_excel_csv_button')}</Button>
            </Whisper>
        </Uploader>
        </>
    );
};

export default FileUploadButton;
