import {Sidenav, Nav, Button} from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import CalendarIcon from '@rsuite/icons/Calendar';
import {Link, useNavigate} from 'react-router-dom';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import { IoLogOutOutline } from "react-icons/io5";
import api, {api_address} from "./http";

export default function SideNav() {
    const {t} = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    function Logout() {
        const fetchData = async () => {
            try {
                const response = await api.post(`/auth/logout`, {},
                    {withCredentials: true})
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                localStorage.removeItem("userData");
                navigate('/login')
            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            }

        };
        fetchData();
    }
    return (
        <div className='sidebar'>
            <Sidenav expanded={expanded} style={{
                position: 'sticky',
                top: 0,
                zIndex: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                paddingBottom: '40px'
            }}>

                <div>
                    <Sidenav.Body>
                        <Nav activeKey="1">

                            <Nav.Item eventKey="1"
                                      icon={<CalendarIcon/>}
                                      as={Link}
                                      to="/events">
                                {t('nav_events')}
                            </Nav.Item>
                            {/*<Nav.Item eventKey="2"*/}
                            {/*    icon={<GridIcon />}*/}
                            {/*    as={Link}*/}
                            {/*    to="/events">*/}
                            {/*    Events*/}
                            {/*</Nav.Item>*/}
                        </Nav>
                    </Sidenav.Body>
                    <Sidenav.Toggle expanded={expanded} onToggle={expanded => setExpanded(expanded)}/>
                </div>
                <div className='logout-container'>
                    <Button className='logout-button'
                            startIcon={<IoLogOutOutline/>} onClick={Logout}>{expanded ? t('logout_button') : null}</Button>
                </div>
            </Sidenav>
        </div>
    )
}