import './Event.css';
import api, {api_address} from "../http";
import React, {useState, useEffect} from "react";
import EventDetails from './event-sections/EventDetails';
import Gallery from './event-sections/Gallery';
import QRCard from './event-sections/QRCard';
import GuestsSection from './event-sections/Guests';
import {Loader, Tabs, Placeholder} from 'rsuite';
import {FaImage} from 'react-icons/fa';
import {TiDocumentText} from "react-icons/ti";
import {FaEnvelopeOpenText} from "react-icons/fa";
import {MdContactPhone} from "react-icons/md";
import {useTranslation} from "react-i18next";


export default function EventPage({id}) {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const lang_direction = currentLanguage === 'he' ? 'rtl' : 'ltr';
    const [eventData, setEventData] = useState(null);
    // const [selectedSection, setSelectedSection] = useState(null);
    const [eventName, setEventName] = useState('');
    const [guestsTabSelected, setGuestsTabSelected] = useState(false);
    const updateEventName = (newEventName) => {
        setEventName(newEventName);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                try {
                    // const response = await fetch("http://localhost:3636/event");
                    const queryParams = new URLSearchParams({
                        id: id
                    });

                    const url = `${api_address}/events/details?${queryParams.toString()}`;
                    const response = await api.get(url,
                        {withCredentials: true});
                    if (response.status !== 200) {
                        throw new Error("Network response was not ok");
                    }
                    const data = response.data;
                    data.eventDetails.date = new Date(data.eventDetails.date);
                    if (data.sms_send_date) {
                        data.sms_send_date = new Date(data.sms_send_date);
                    }
                    setEventData(data);
                    setEventName(data.eventDetails.name)
                    // setSelectedSection();
                } catch (error) {
                    console.error("Error fetching event data:", error);
                    throw error;
                }

            } catch (error) {
                // Handle errors if needed
            }
        };
        fetchData();
    }, [id]);

    function handleTabSelection(event_id) {
        if (event_id === '4') {
            setGuestsTabSelected(true)
        } else {
            setGuestsTabSelected(false)
        }
    };

    if (!eventData) {
        return (
            <div style={{display: "flex", justifyContent: 'center', flexDirection: 'column', height: '100vh',
                width: '100%'}}><Loader
                size="lg"/></div>);
    }


    // const jsDate = new Date(eventData.eventDetails.date);
    // const formattedDate = jsDate.toLocaleDateString('en-GB');
    return (
        <div className='event-main'>
            <section className='event-header'>
                <div className='title'>
                    <h1>{eventName}</h1>
                    {eventData.demo ? <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='demo-tag'>
                            <span>{t('demo_tag')}</span>
                        </div>
                    </div> : null}
                    <h3>{eventData.eventDetails.date.toLocaleDateString('en-GB')}</h3>
                </div>
            </section>
            <div className='event-tabs'>
                <Tabs onSelect={handleTabSelection} defaultActiveKey="1">
                    <Tabs.Tab eventKey="1" title={t('event_details_tab')} icon={<TiDocumentText/>}>
                        <section>
                            <EventDetails updateEventName={updateEventName} data={eventData}/>
                        </section>
                    </Tabs.Tab>
                    <Tabs.Tab eventKey="2" title={t('gallery_tab')} icon={<FaImage/>}>
                        <Gallery id={id} GalleryPublic={eventData.gallery_public}/>
                    </Tabs.Tab>
                    <Tabs.Tab eventKey="3" title={t('qr_card_tab')} icon={<FaEnvelopeOpenText/>}>
                        <QRCard id={id}/>
                    </Tabs.Tab>
                    <Tabs.Tab eventKey="4" title={t('guests_tab')} icon={<MdContactPhone/>}>
                        <GuestsSection eventId={id} guestsTabSelected={guestsTabSelected} eventName={eventName}
                                       eventDate={eventData.eventDetails.date}
                                       eventCountry={eventData.eventDetails.country}
                                       smsSendDate={eventData.sms_send_date}
                                       guestsLimit={eventData.guests_limit}/>
                    </Tabs.Tab>
                </Tabs>
            </div>

        </div>
    );
}
