import './App.css';
// import FileUploader from './components/FileUploader'
import FileUploader from './components/FileUploaderr'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './components/Home';
import RootLayout from './components/Root';
import EventsPage from './components/events/Events';
import LoginPage from './components/login/login';
import ForgotPassword from "./components/login/forgot-password";
import SignUp from './components/login/sign-up';
import OAuthRedirect from "./components/login/GoogleLoginRedirect";
import PublicGallery from "./components/events/event-sections/PublicGallery";
import CardTemplates from "./components/events/CardTemplates";

const router = createBrowserRouter([
  { path: '/login', element: <LoginPage />},
  { path: '/oauth-redirect', element: <OAuthRedirect />},
  { path: '/signup', element: <SignUp />},
  { path: '/reset-password', element: <ForgotPassword />},
  {
    path: '/', element: <RootLayout />, children: [
      { path: '/', element: <EventsPage /> },
      { path: '/events', element: <EventsPage />},
      { path: '/card-templates', element: <CardTemplates />}
    ]
  },
  { path: '/upload', element: <FileUploader /> },
  { path: '/gallery', element: <PublicGallery /> }
])


function App() {

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
