import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'rsuite';
import { FaRegFlag } from 'react-icons/fa';



const getFlag = (countryCode) => {
    const offset = 127397; // for converting ascii to regional indicator symbols
    return String.fromCodePoint(...[...countryCode.toUpperCase()].map((c) => c.charCodeAt() + offset));
};

const Flag = ({ countryCode }) => <span>{getFlag(countryCode)}</span>;
const LANGUAGES = {
    ENGLISH: { code: 'us', language: 'en' },
    HEBREW: { code: 'il', language: 'he' },
};

function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [currentFlag, setCurrentFlag] = useState(<Flag countryCode={LANGUAGES.ENGLISH.code} />);
    const [currentLanguage, setCurrentLanguage] = useState(LANGUAGES.ENGLISH.language);

    const selectLanguage = ({ language, code }) => {
        i18n.changeLanguage(language);
        setCurrentFlag(<Flag countryCode={code} />);
        setCurrentLanguage(language)
    };

    const handleLanguageSelect = (selectedLanguage) => {
        selectLanguage(LANGUAGES[selectedLanguage]);
    }

    return (
        <Dropdown title={currentLanguage} icon={currentFlag}>
            <Dropdown.Item onSelect={() => handleLanguageSelect('ENGLISH')}>
                <Flag countryCode={LANGUAGES.ENGLISH.code} /> English
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => handleLanguageSelect('HEBREW')}>
                <Flag countryCode={LANGUAGES.HEBREW.code} /> Hebrew
            </Dropdown.Item>
        </Dropdown>
    );
}

export default LanguageSwitcher;
