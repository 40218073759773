// SignUp.js
import React, {useState} from 'react';
import {Form, Button, Container, Header, Content, FlexboxGrid, toaster, Message, Checkbox} from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './sign-up.css';
import {api_address} from "../http";
import { useNavigate} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const SignUp = () => {
    const [formValue, setFormValue] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });
    const [showValidationForm, setShowValidationForm] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const navigate = useNavigate(); // Updated to use useNavigate

    const handleChange = (value, name) => {
        setFormValue({
            ...formValue,
            [name]: value
        });
    };
    const handleValidationChange = (value) => {
        setValidationCode(value);
    };

    const isValidPassword = (password) => {
        // at least 8 chars
        // contains a lowercase letter
        // contains a number
        const re = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;
        return re.test(password);
    };

    const handleSubmit = async () => {
        if(!(captchaValue)){ // Add this in your condition.
            toaster.push(
                <Message showIcon header='You forgot something..' type="error" >Please verify that you are a human</Message>,
                { placement: 'topCenter', duration: 5000}
            );
            return;
        }
        if (!(formValue.firstName && formValue.lastName && formValue.email && formValue.password )) {
            toaster.push(
                <Message showIcon header='Looks like you forgot something..' type="error" >Please fill in all the details correctly</Message>,
                { placement: 'topCenter', duration: 5000}
            );
            return;
        } else if (!isValidPassword(formValue.password)) {
            toaster.push(
                <Message showIcon header='Please change your password' type="error" >The password need to have at least 8 characters and must contain 1 lowercase letter and 1 number</Message>,
                { placement: 'topCenter', duration: 5000}
            );
            return;
        }
        const requestBody = {
            ...formValue,
            captcha_token: captchaValue,
        }
        const response = await fetch(`${api_address}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.ok) {
            // Handle success - maybe clear form or show a success message
            console.log('Registration successful');
        } else if (response.status === 409) {
            // User already exists error, show specific message
            toaster.push(
                <Message showIcon header='Oh No..' type="error" >This user already exists, if you logged in with Google please use that method instead.</Message>,
                { placement: 'topCenter', duration: 5000}
            );
        }else {
            // Handle error - show an error message
            console.log('Registration failed');
        }
        if (response.status === 201) {
            // Registration successful, show validation code form
            setShowValidationForm(true);
        }
    };

    const handleValidationSubmit = async () => {
        const validationRequestBody = {
            email: formValue.email,
            validationCode: validationCode,
        };
        const response = await fetch(`${api_address}/auth/confirm-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(validationRequestBody),
        });

        if (response.ok) {
            // Validation successful, redirect to login page
            navigate('/login');
        } else {
            // Validation failed, show error message
            Notification.error({
                title: 'Error',
                description: 'Wrong code. Please try again.'
            });
        }
    };


    return (
        <div className='sign-up-main-view'>
            <div style={{width: '50%', maxWidth: '200px'}}>
                <img style={{width: "100%", height: 'auto'}}
                     src={`${process.env.REACT_APP_CLOUDFRONT_APP_ASSETS}/logo.svg`} alt='App logo'></img>
            </div>
            <div className='signup-container'>
                <Header><h2 style={{marginBottom: '20px'}}>Sign Up</h2></Header>
                {!showValidationForm ? (<Content>
                        <Form fluid onChange={setFormValue} formValue={formValue}>
                            <Form.Group>
                                <Form.Control name="firstName" placeholder="First Name"
                                              onChange={(value) => handleChange(value, 'firstName')}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control name="lastName" placeholder="Last Name"
                                              onChange={(value) => handleChange(value, 'lastName')}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control name="email" type="email" placeholder="Email"
                                              onChange={(value) => handleChange(value, 'email')}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control name="password" type="password" placeholder="Password"
                                              onChange={(value) => handleChange(value, 'password')}/>
                            </Form.Group>
                            <Form.Group>
                                <Checkbox checked={termsAgreed} onChange={() => {
                                    setTermsAgreed(!termsAgreed)
                                }}>
                                    By checking this box I agree to the <a href='https://qr-event.net/terms-and-conditions'
                                                                           target='_blank'>Terms and Conditions</a> and
                                    the <a href='https://qr-event.net/privacy-policy' target='_blank'>Privacy
                                    Policy</a>
                                </Checkbox>
                            </Form.Group>
                            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
                                <ReCAPTCHA
                                    sitekey="6LerEIspAAAAACr3tHF2HmV33tiPhp8_re6y-hCd"
                                    onChange={value => setCaptchaValue(value)}
                                />
                            </div>
                            <Button disabled={!(termsAgreed && captchaValue)} appearance="primary" onClick={handleSubmit}>Sign Up</Button>
                        </Form>
                    </Content>) :
                    (
                        <Form fluid>
                            <Form.Group>
                            <Form.Control name="validationCode" placeholder="Validation Code"
                                              onChange={handleValidationChange}/>
                            </Form.Group>
                            <Button appearance="primary" onClick={handleValidationSubmit}>Validate</Button>
                        </Form>)}
            </div>
        </div>
    );
};

export default SignUp;
