import React, {useEffect, useState} from 'react';
import './Guests.css';
// import sms from '../../../assets/sms.png';
import ios_frame from '../../../assets/sms_ui_transparent_bg.png';
import {Button, Table, DatePicker, toaster, Notification, Tooltip, Whisper} from 'rsuite';
import api, {api_address} from "../../http";
import {FaFileExcel} from "react-icons/fa";
import FileUploadButton from "./GuestsFileUploader";
import {useTranslation} from "react-i18next";
import {IoHelpCircleOutline} from "react-icons/io5";


const {Column, HeaderCell, Cell} = Table;

const ActionCell = ({rowData, dataKey, onClick, ...props}) => {
    const {t} = useTranslation();
    return (
        <Cell {...props} style={{padding: '6px'}}>
            <Button
                appearance="link"
                onClick={() => {
                    onClick(rowData.phone);
                }}
            >
                {t('delete')}
            </Button>
        </Cell>
    );
};

const Label = props => {
    return <label style={{width: 120, display: 'inline-block', marginTop: 10}} {...props} />;
};

const GuestsSection = ({
                           eventId,
                           guestsTabSelected,
                           eventName,
                           eventDate,
                           eventCountry,
                           guestsLimit,
                           smsSendDate = null
                       }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const lang_direction = currentLanguage === 'he' ? 'rtl' : 'ltr';
    const [guests, setGuests] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [dataChanged, setDataChanged] = React.useState(0);
    const [shouldAutoHeight, setShouldAutoHeight] = React.useState(true);
    let hey_string, ending, isEnglish, formattedMessage;
    const upload_link = 'https://qr-event.net/upload'
    if (eventCountry === 'IL') {
        hey_string = 'היי! תודה שהשתתפתם ב';
        ending = 'שתפו אותנו בחוויה ובתמונות שלכם כאן'
        isEnglish = /^[A-Za-z]$/.test(eventName[0]);
        formattedMessage = isEnglish ? eventName + hey_string : hey_string + eventName;
    } else {
        hey_string = 'Hey! Thank you for participating in ';
        ending = 'please share your moments and experience with us here'
        formattedMessage = hey_string + eventName
    }


    useEffect(() => {
        setShouldAutoHeight(guestsTabSelected)
    }, [guestsTabSelected]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`${api_address}/events/${eventId}/guests`, {withCredentials: true})
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                setGuests(response.data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            }

        };
        fetchData();
    }, [dataChanged]);

    const handleDataChanged = () => {
        setDataChanged(prevCount => prevCount + 1);
    };

    const beforeStartDate = (date) => {
        return date < eventDate;
    };

    const handleDelete = (phone_number) => {
        const phoneNumber = guests.find(guest => guest.phone === phone_number).phone;
        api.delete(`${api_address}/events/${eventId}/guests/${phoneNumber}`, {withCredentials: true})
            .then(res => {
                if (res.status === 200) {
                    // Remove the deleted guest from guests array
                    setGuests(guests.filter(guest => guest.phone !== phone_number));
                } else {
                    throw new Error("Failed to delete guest");
                }
            })
            .catch(error => console.error(error));
    };

    const handleSendDateChange = (new_date) => {
        if (new_date !== eventDate) {
            api.put(`${api_address}/events/${eventId}/guests/sms_send_date`, {new_date: new_date},
                {withCredentials: true})
                .then(res => {
                    if (res.status === 200) {
                        toaster.push(
                            <Notification type="success" header="Success" closable>
                                SMS send date updated successfully.
                            </Notification>,
                            {placement: 'topEnd'}
                        );
                    } else {
                        throw new Error("Failed to update sms send date");
                    }
                })
                .catch(error => console.error(error));
        }
    };

    return (
        <div className='guests-tab-content'>
            <div className="phone-container">
                <div className='phone-content-wrapper'>
                    <img src={ios_frame} alt="iOS Phone Frame" className="phone-frame"/>
                    <p className="message-text"
                       style={{textAlign: (eventCountry === 'IL') ? 'right' : 'left'}}>{formattedMessage}<br/>{ending}<br/>{upload_link}
                    </p>
                </div>
            </div>
            <div className='guests-tab-table'>
                <div style={{marginBottom: '25px', display: 'flex', justifyContent: 'space-between'}}>
                    <div dir={lang_direction}>
                        <Label dir={lang_direction}>{t('send_sms_date_label')} </Label>
                        <DatePicker
                            dir='ltr'
                            format="dd/MM/yyyy HH:mm"
                            shouldDisableDate={beforeStartDate}
                            placeholder="Select SMS Send Date"
                            onChange={handleSendDateChange}
                            value={smsSendDate}
                        />
                    </div>

                </div>
                <div style={{marginBottom: '10px', display: 'flex', justifyContent: 'space-between'}} >
                    <div style={{display: 'flex', alignItems: 'center'}} >
                        <div >{t('guests_limit')} <strong >{lang_direction === 'ltr' ? `${guests.length} / ${guestsLimit}` : `${guestsLimit} / ${guests.length}`}</strong></div>
                        <div >
                            <Whisper
                                placement="auto"
                                trigger={['hover', 'click']}
                                speaker={<Tooltip dir={lang_direction}>{t('guests_limit_tooltip')}</Tooltip>}
                            >

    <span style={{display: 'flex', alignItems: 'center', marginLeft: '3px'}}>
      <IoHelpCircleOutline style={{cursor: 'pointer'}}/>
    </span>
                            </Whisper>
                        </div>
                    </div>
                    <div className='guests-uploader-container'>
                        <FileUploadButton eventId={eventId} handleDataChanged={handleDataChanged}/>
                    </div>
                </div>
                <Table data={guests} bordered={true} loading={loading} fillHeight={shouldAutoHeight}>
                    <Column flexGrow={1}>
                        <HeaderCell>{t('phone_number')}</HeaderCell>
                        <Cell dataKey="phone"/>
                    </Column>
                    <Column flexGrow={1}>
                        <HeaderCell>{t('name')}</HeaderCell>
                        <Cell dataKey="name"/>
                    </Column>
                    <Column flexGrow={1}>
                        <HeaderCell>{t('delete_contact')}</HeaderCell>
                        <ActionCell dataKey="phone" onClick={handleDelete}/>
                    </Column>
                </Table>
            </div>
        </div>
    );
}
export default GuestsSection;