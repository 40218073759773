import { QRCode } from 'react-qrcode-logo';
import React, {useEffect, useState} from "react";
import {Button, Loader, Panel} from 'rsuite';
import './QRCard.css';
import api, { app_address } from '../../http';
import {useTranslation} from "react-i18next";
import {IoShareOutline} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
export default function QRCard({id}) {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const lang_direction = currentLanguage === 'he' ? 'rtl' : 'ltr';
    const url = `${app_address}/upload?eventId=${id}`;
    const [cardTemplates, setCardTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/events/card-templates`,
                    {withCredentials: true})
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                setCardTemplates(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            }

        };
        fetchData();
    }, []);

    if (isLoading) {
        return (
            <div style={{display: "flex", justifyContent: "center" ,alignItems: 'center', flexDirection: 'column', height: '100vh',
                width: '100%'}}><Loader
                size="lg"/></div>);
    }

    return (
        <div className='qr-card-tab' dir={lang_direction}>
            <div style={{marginBottom: '50px'}}>
                <h2 >
                    {t('qr_card_tab_title')}
                </h2>
            </div>
            <div>
                <h3>
                    {t('step_1_title')}
                </h3>
                <p>
                    {t('step_1_description')}
                </p>
                <div className='card-templates'>
                    {cardTemplates.map((item, index) => (
                        <div className='template' key={index}>
                            <a href={item.url} target='_blank'>
                                <Panel shaded bordered bodyFill >
                                    <div className="card-image">
                                        <img src={`https://d3gqgo9xkghzui.cloudfront.net/card-templates/${item.language}/${item.name}.png`}/>
                                    </div>
                                    <Panel header={item.title}>
                                    </Panel>
                                </Panel>
                            </a>
                        </div>
                    ))}
                </div>
                <Button onClick={() => {navigate('/card-templates')}}  appearance="link">{t('see_more')}</Button>
            </div>
            <div style={{marginTop: 50}}>
                <h3>
                    {t('step_2_title')}
                </h3>
                <p>
                    {t('step_2_description')}
                </p>
                <div style={{margin: "25px"}}>
                    <h4>
                        {t('your_event_qr_code')}
                    </h4>
                    {/*<QRCode value={url} size={256}/>*/}
                    <div style={{width: '100%', display:'flex', justifyContent:'center'}}>
                        <div style={{width: '20%'}}>

                            <QRCode value={url}
                                    size={250}
                                    ecLevel={'H'}
                                    eyeRadius={4}
                                    qrStyle='squares'
                                    // logoImage='/logo.svg'
                                    quietZone={0}
                            />
                    </div>
                    </div>
                </div>
                <div className='step-gif'>
                    {/*<img src='https://d3gqgo9xkghzui.cloudfront.net/step2.gif'/>*/}
                    <video src={`${process.env.REACT_APP_CLOUDFRONT_APP_ASSETS}/copy_qr_video1080.mov`} autoPlay muted loop></video>

                </div>
            </div>
            {/*<div style={{marginBottom: 100, marginTop: 50}}>*/}
            {/*    <h3>*/}
            {/*        {t('step_3_title')}*/}
            {/*    </h3>*/}
            {/*    <p>*/}
            {/*        {t('step_3_description')}*/}
            {/*    </p>*/}
            {/*    <div className='step-gif' style={{marginTop: "25px"}}>*/}
            {/*        <img src='https://d3gqgo9xkghzui.cloudfront.net/step3.gif' height='auto' width="100%"/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}