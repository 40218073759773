// import { ReactPhotoCollage } from "react-photo-collage";
import './PublicGallery.css';
import api, {api_address} from "../../http";
import React, {useEffect, useState} from "react";
import {Loader, Button, Modal} from "rsuite";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import {FaUpload} from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";


export default function PublicGallery() {
    const { t } = useTranslation();
    const [eventImages, setEventImages] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const location = useLocation();
    const [lastId, setLastId] = useState(null);
    const [moreDataAvailable, setMoreDataAvailable] = useState(true);
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('eventId');
    const mainBannerImage = `https://qr-event-media.s3.amazonaws.com/${id}/banner.jpeg`;
    const [backgroundImage, setBackgroundImage] = useState(`url(${mainBannerImage})`);
    if (id === null) {
        window.location.replace('/');
    }


    useEffect(() => {
        const img = new Image();
        img.src = mainBannerImage;
        img.onload = () => setBackgroundImage(`url(${mainBannerImage})`);
        img.onerror = () => {
            setBackgroundImage(`url(${process.env.REACT_APP_CLOUDFRONT_APP_ASSETS}/default_banner.jpg)`);
        };
    }, []);
    const fetchData = async () => {
        try {
            try {
                const response = await axios.get(`${api_address}/events/${id}/images/public`,
                    {params: {
                            last_id: lastId ?? '',
                        }});
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                const data = response.data;
                if (firstLoad) {
                    setEventImages(data)
                    setFirstLoad(false)
                } else {
                    setEventImages((prevImages) => [...prevImages, ...data]);
                }
                if (data.length > 0) {
                    setLastId(data[data.length - 1].id);
                } else {
                    setMoreDataAvailable(false );
                }

            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            }

        } catch (error) {
            // Handle errors if needed
        }
    };

    useEffect(() => {

        fetchData();
    }, [id]);

    if (eventImages === null) {
        return (
            <div style={{display: "flex", justifyContent:'center', flexDirection: 'column', height: '100vh',
                alignItems:"center",
                width: '100%'}}><Loader
                size="lg"/></div>)
    } else if (eventImages.length === 0) {
        return (<div>
            <div style={{textAlign: "right", marginRight: "30px"}}>
                <a href={`/upload?eventId=${id}`} target="_blank"><Button startIcon={<FaUpload/>}>Upload</Button></a>
            </div>
            <div style={{textAlign: "center"}}>
                <h3>No images uploaded yet..</h3>
            </div>
        </div>)
    }

    // const handleImageError = () => {
    //     setMainBannerImage(''); // Setting the source to an empty string
    // };

    return (<div>
            {( mainBannerImage ?
            <div className='banner' style={{backgroundImage: backgroundImage}}>
                {/*{mainBannerImage && <img style={{height:'100%', width:'auto'}} src={mainBannerImage} onError={handleImageError}/>}*/}
            </div> : null)}
            <div className="publicLightGallery">
                <InfiniteScroll
                    dataLength={eventImages.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={moreDataAvailable}
                    loader={<div style={{height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div><Loader size="lg"/></div></div>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                <LightGallery
                    mode="lg-slide"
                    licenseKey="2E23DB13-6A9B-49AE-9F45-5F3A2D8DB992"
                    speed={500}
                    plugins={[lgZoom, lgVideo, lgThumbnail]}>
                    {eventImages.map((item) => (
                        item.file_type.includes('image') ?
                            <div key={item.id}
                                 className={`gallery-item`}
                                 href={item.src}
                                 data-src={item.src}
                                 data-sub-html={item.subHtml}>
                                {/*data-lg-size="1406-1390">*/}

                                <img src={item.thumb} alt=""/>

                            </div>
                            :
                            <div key={item.id}
                                 className={`gallery-item`}
                                 data-video={`{"source": [{"src":"${item.src}", "type":"video/mp4"}], "attributes": {"preload": false, "playsinline": true, "controls": true}}`}
                                 data-sub-html={item.subHtml}>
                                <img src={item.thumb} alt=""/>

                            </div>
                    ))}
                </LightGallery>
                </InfiniteScroll>
            </div>
        </div>
    );

}