import api, {api_address} from "../http";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Loader, Panel} from "rsuite";
import './CardTemplates.css';

export default function CardTemplates() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const lang_direction = currentLanguage === 'he' ? 'rtl' : 'ltr';
    const [cardTemplates, setCardTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/events/card-templates?featured=false`,
                    {withCredentials: true})
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                setCardTemplates(response.data);
            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            } finally {
                setIsLoading(false); // Add this line
            }

        };
        fetchData();
    }, []);

    if (isLoading) {
        return (
            <div style={{display: "flex", justifyContent: 'center', flexDirection: 'column', height: '100vh',
                width: '100%'}}><Loader
                size="lg"/></div>);
    }

    return (
        <div>
            <div className='card-templates'>
                {cardTemplates.map((item, index) => (
                    <div className='template' key={index}>
                        <a href={item.url} target='_blank'>
                            <Panel shaded bordered bodyFill>
                                <div className="card-image">
                                    <img
                                        src={`https://d3gqgo9xkghzui.cloudfront.net/card-templates/${item.language}/${item.name}.png`}/>
                                </div>
                                <Panel header={item.title}>
                                    {/*<p style={{color: 'black'}}>*/}
                                    {/*    <small>*/}
                                    {/*        This is more {item.name.toLowerCase()}.*/}
                                    {/*    </small>*/}
                                    {/*</p>*/}
                                </Panel>
                            </Panel>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}
