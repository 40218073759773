import './forgot-password.css';
import React, { useState } from 'react';
import { Form, Button, Notification } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {api_address} from "../http";

const ForgotPassword = () => {
    const [stage, setStage] = useState(1); // 1 for request reset, 2 for complete reset
    const [username, setUsername] = useState('');
    const [validationCode, setValidationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleRequestReset = async () => {
        try {
            const response = await axios.post(`${api_address}/auth/reset-password`, { username });
            if (response.status === 200) {
                setStage(2); // Move to next form
            } else {
                Notification.error({
                    title: 'Reset Failed',
                    description: 'Could not initiate password reset. Please try again.',
                });
            }
        } catch (error) {
            Notification.error({
                title: 'Error',
                description: 'An error occurred. Please try again later.',
            });
        }
    };

    const handleCompleteReset = async () => {
        if (newPassword !== confirmPassword) {
            Notification.error({
                title: 'Error',
                description: 'Passwords do not match.',
            });
            return;
        }

        try {
            const response = await axios.post(`${api_address}/auth/complete-reset-password`, {
                username,
                validationCode,
                newPassword,
            });
            if (response.status === 200) {
                Notification.success({
                    title: 'Success',
                    description: 'Password has been reset successfully.',
                });
                navigate('/login');
            } else {
                Notification.error({
                    title: 'Reset Failed',
                    description: 'Could not complete password reset. Please try again.',
                });
            }
        } catch (error) {
            Notification.error({
                title: 'Error',
                description: 'An error occurred. Please try again later.',
            });
        }
    };

    return (
        <div className='main-view'>
        <div className="reset-password-container">
            {stage === 1 ? (
                <Form fluid>
                    <Form.Group>
                        <Form.Control
                            name="username"
                            placeholder="Email"
                            value={username}
                            onChange={setUsername}
                        />
                    </Form.Group>
                    <Button appearance="primary" block onClick={handleRequestReset}>
                        Reset Password
                    </Button>
                </Form>
            ) : (
                <Form fluid>
                    <Form.Group>
                        <Form.Control
                            name="validationCode"
                            placeholder="Validation Code"
                            value={validationCode}
                            onChange={setValidationCode}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            name="newPassword"
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={setNewPassword}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={setConfirmPassword}
                        />
                    </Form.Group>
                    <Button appearance="primary" block onClick={handleCompleteReset}>
                        Complete Password Reset
                    </Button>
                </Form>
            )}
        </div>
        </div>
    );
};

export default ForgotPassword;

