import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

i18n
    // Pass the i18n instance to react-i18next.
    .use(Backend)
    .use(initReactI18next)
    // Add any additional plugins here, e.g., backend loading or language detection.
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        lng: "en", // default language
        fallbackLng: "en", // use English if translation is not available
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });
