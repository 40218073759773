import React, {useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import {Popover, Whisper, Button, ButtonToolbar, Input} from 'rsuite';
import {useTranslation} from "react-i18next";
import api, {api_address} from "./http";

const ColorPickerDropdown = ({eventId, eventData, exampleTextColorSetter, bannerTextChangeHandler}) => {
    // if(obj.hasOwnProperty('banner_details')) {
    //     console.log('Key exists');
    // } else {
    //     let text_color = '#000000'
    // }
    const [textColor, setTextColor] = useState(eventData.banner_details.text_color);
    const [bannerText, setBannerText] = useState(eventData.banner_details.banner_text);
    const [changedDetails, setChangedDetails] = React.useState(false);
    const {t} = useTranslation();

    function handleNewColor(newColor) {
        setTextColor(newColor);
        setChangedDetails(true);
        exampleTextColorSetter(newColor);
    }

    function handleNewText(newText) {
        setBannerText(newText);
        bannerTextChangeHandler(newText);
        setChangedDetails(true);
    }

    function handleBannerColorUpdate() {
        (async () => {
            try {
                const response = await api.put(`/events/${eventId}/banner-details`,
                    {text_color: textColor, banner_text: bannerText},
                    {withCredentials: true});
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                setChangedDetails(false)
            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            }
        })();

    }

    const pickerPopover = (
        <Popover title="Choose Color">
            <div style={{padding: '4px'}}>
                <HexColorPicker color={textColor} onChange={(newColor) => handleNewColor(newColor)}/>
            </div>
        </Popover>
    );

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <ButtonToolbar>
                    <Whisper placement="auto" trigger="click" speaker={pickerPopover}>
                        <Button style={{
                            width: 30,
                            height: 30,
                            backgroundColor: textColor,
                            border: '1px solid rgba(0,0,0,0.2)'
                        }}/>
                    </Whisper>
                </ButtonToolbar>
                <span style={{marginLeft: '10px'}}>{t('banner_text_color')}</span>
            </div>
            <Input value={bannerText} onChange={handleNewText} placeholder={t('banner_text')} style={{marginTop: '8px'}}/>
            {changedDetails ? <Button style={{marginTop: '5px'}} appearance="primary"
                                      onClick={handleBannerColorUpdate}>{t('update_button')}</Button> : null}
        </>
    );
};

export default ColorPickerDropdown;
