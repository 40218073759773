import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Uploader, toaster, Message} from 'rsuite';
import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro';
import './FileUploader.css';
import {useLocation} from "react-router-dom";
import api, { api_address } from './http';
import { Progress } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';

const FileUploader = () => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [startedUpload, setStartedUpload] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('eventId');
    const [bannerText, setBannerText] = useState(null);
    const mainBannerImage = `https://qr-event-media.s3.amazonaws.com/${id}/banner.jpeg`;
    const [uploaderKey, setUploaderKey] = useState(0);
    const [backgroundImage, setBackgroundImage] = useState(`url(${mainBannerImage})`);
    const [style, setStyle] = useState({color: 'black'});


    if (id === null) {
        window.location.replace('/');
    }

    const showNotification = () => {
        toaster.push(
            <Message showIcon header='Oh No..' type="error" >The event reached the maximum media upload limit!</Message>,
            { placement: 'topCenter', duration: 5000}
        );
    };

    useEffect(() => {
        const fetchData = async () => {

            try {
                // const response = await fetch("http://localhost:3636/event");
                const queryParams = new URLSearchParams({
                    id: id
                });

                const url = `${api_address}/events/${id}/banner-details`;
                const response = await api.get(url);
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                const data = response.data.banner_details;
                setStyle({color: data.text_color})
                if (data.banner_text) {
                    setBannerText(data.banner_text)
                } else {
                    setBannerText('Press Here To Upload Your Photos!')
                }
            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            }

        };
        fetchData();
    }, [id]);

    useEffect(() => {
        const img = new Image();
        img.src = mainBannerImage;
        img.onload = () => setBackgroundImage(`url(${mainBannerImage})`);
        img.onerror = () => {
            setBackgroundImage(`url(${process.env.REACT_APP_CLOUDFRONT_APP_ASSETS}/default_banner.jpg)`);
            // setStyle({ color: 'black'}); // assuming white background when no image
        };
    }, []);

    useEffect(() => {
        if (uploadedFiles > 0) {
            // console.log(`Progress ${uploadedFiles} / ${totalFiles}`);
            setUploadProgress((uploadedFiles / totalFiles) * 100);
        }
    }, [uploadedFiles, totalFiles]);
    const handleUpload = async (selectedFile) => {
        const parts = selectedFile.name.split('.');
        const format = parts.pop();
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('file_name', `${uuidv4()}.${format}`);
        formData.append('file_type', selectedFile.type);
        formData.append('event_id', id);


        axios.post(`${api_address}/media/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(() => {
            setUploadedFiles(prevCount => prevCount + 1); // Use functional update form
        }).catch((error) => {
            if (error.response && error.response.status === 402) {
                showNotification()
            }
            console.error('Error completing upload:', error);
        });
    };
    const uploadFile = async (file) => {
        if (!file) {
            return;
        }

        await handleUpload(file);
    };

    const handleUploadChange = (fileList) => {
        setUploadProgress(0);
        setUploadedFiles(0);
        setStartedUpload(true);
        setTotalFiles(fileList.length);
        fileList.forEach(({blobFile}) => uploadFile(blobFile));
        setUploaderKey(prevKey => prevKey + 1);
    };

    return (
        <div className="uploader-element" style={{backgroundImage: backgroundImage}}>
            <div>

                <div className='uploader-section'>
                    {startedUpload && <div style={{width:'100%', justifyContent:'center', display:'flex', marginBottom: '20px'}}>
                        <div style={{width:'50%'}}>
                            {uploadProgress >= 100 ? <Progress.Circle percent={100} status="success" />:
                                <Progress.Circle percent={Math.round(uploadProgress)} strokeColor="#ffc107"/>}
                        </div>

                    </div>}
                    <Uploader
                        autoUpload={false}
                        key={uploaderKey}
                        multiple
                        onChange={handleUploadChange}
                        removable={true}
                        listType="picture-text"
                        accept=".jpg,.jpeg,.png,.gif, .mp4, .mov, .avi, .mkv"

                    >
                            <button style={{backgroundColor: "rgba(255, 255, 255, 0.1)", ...style}}>
                            <CameraRetroIcon style={{fontSize: '48px'}}/>
                            <h3>{bannerText}</h3>
                        </button>
                    </Uploader>

                </div>
            </div>
        </div>
    );
};

export default FileUploader;
