import {Form, ButtonToolbar, Button, Input, DatePicker, Uploader, toaster, Notification, SelectPicker} from 'rsuite';
import React, {useEffect, useState} from 'react';
import api, {api_address} from "../../http";
import {FaFileExcel, FaCloudUploadAlt} from "react-icons/fa";
import SupportedCountries from '../../../assets/SupportedCountries.json';
import {useTranslation} from "react-i18next";
import ColorPicker from "../../ColorPicker";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
const Label = props => {
    return <label style={{width: 120, display: 'inline-block', marginTop: 10}} {...props} />;
};

export default function EventDetails({data = null, updateEventName}) {
    const { t } = useTranslation();
    const [formValue, setFormValue] = React.useState(data.eventDetails);
    const [changedDetails, setChangedDetails] = React.useState(false);
    const [changedFields, setChangedFields] = React.useState({});
    const [countries, setCountries] = React.useState(SupportedCountries);
    const [selectedCountry, setSelectedCountry] = React.useState(data.eventDetails.country);
    const [bannerTextColor, setBannerTextColor] = useState(data.banner_details.text_color);
    const [bannerText, setBannerText] = useState(data.banner_details.banner_text);
    const [mainBannerImage, setMainBannerImage] = React.useState(`https://qr-event-media.s3.amazonaws.com/${data.id}/banner.jpeg`);
    function onChangeDetails(newFormValues) {
        let changed = {};
        console.log(newFormValues)
        Object.keys(newFormValues).forEach(key => {
            if (newFormValues[key] !== formValue[key]) {
                changed[key] = newFormValues[key];
            }
        });
        setChangedFields({...changedFields, ...changed});
        setFormValue(newFormValues);
        setChangedDetails(true);
    }


    const handleImageError = () => {
        setMainBannerImage(`${process.env.REACT_APP_CLOUDFRONT_APP_ASSETS}/default_banner.jpg`);
        // setMainBannerImage('')
    };

    function handleBannerTextChange(newValue) {
      setBannerText(newValue);
    };
    function handleDetailsUpdate() {
            (async () => {
                try {
                    const response = await api.put(`${api_address}/events/${data.id}/details`,
                        changedFields,
                        {withCredentials: true});
                    if (response.status !== 200) {
                        throw new Error("Network response was not ok");
                    }
                    setChangedFields({})
                    setChangedDetails(false)
                    updateEventName(formValue.name);
                } catch (error) {
                    console.error("Error fetching event data:", error);
                    throw error;
                }
            })();

    }

    const handleUpload = async (fileList) => {
        try {
            const file = fileList[fileList.length - 1]; // Get the last selected file
            const formData = new FormData();
            formData.append('file', file.blobFile);

            const response = await api.post(`/events/${data.id}/banner`, formData, { withCredentials: true });

            if (response.status === 200) {
                toaster.push(
                    <Notification type="success" header="Success" closable>
                        File uploaded successfully.
                    </Notification>,
                    { placement: 'topEnd' }
                );
            } else {
                toaster.push(
                    <Notification type="error" header="Error" closable>
                        Image upload failed
                    </Notification>,
                    { placement: 'topEnd' });
            }
        } catch (error) {
            toaster.push(
                <Notification type="error" header="Error" closable>
                    Image upload failed
                </Notification>,
                { placement: 'topEnd' });
        }
    };


    return (
        <div className='event-tab-content'>
            <Form
                formValue={formValue}
                onChange={formValue => onChangeDetails(formValue)}>
                <Form.Group controlId="name">
                    <Form.ControlLabel>{t('name')}</Form.ControlLabel>
                    <div className='form-control-helper'>
                        <Form.Control name="name"/>
                        <Form.HelpText tooltip>{t('name_helptext')}</Form.HelpText>
                    </div>
                </Form.Group>
                <Form.Group controlId="date">
                    <Form.ControlLabel>{t('date')}</Form.ControlLabel>
                    <Form.Control name="date" type="date" readOnly={true} accepter={DatePicker}/>
                    <Form.HelpText tooltip>{t('date_helptext')}</Form.HelpText>
                </Form.Group>
                <Form.Group controlId="guests">
                    <Form.ControlLabel>{t('guests')}</Form.ControlLabel>
                    <Form.Control name="guests" type="number"/>
                    <Form.HelpText tooltip  style={{marginLeft:'125px'}}>{t('guests_helptext')}</Form.HelpText>
                </Form.Group>


                <Form.Group controlId="country">
                    <Form.ControlLabel>{t('event_country')}</Form.ControlLabel>
                    <Form.Control name="country" accepter={SelectPicker} data={countries} />
                    <Form.HelpText tooltip>{t('event_country_helptext')}</Form.HelpText>
                </Form.Group>

                {changedDetails ? <Button appearance="primary" onClick={handleDetailsUpdate}>{t('update_button')}</Button> : null}
            </Form>
            <div className='banner-image-section'>
                <div className='banner-details'>
                    <ColorPicker eventId={data.id} eventData={data} exampleTextColorSetter={setBannerTextColor} bannerTextChangeHandler={handleBannerTextChange}/>

                </div>
                <div className='uploader-container'>
                    <Uploader
                        autoUpload={false}
                        onChange={handleUpload}
                        accept="image/*"
                        style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}
                    >
                        <Button startIcon={<FaCloudUploadAlt/>}>{t('upload_new_banner')}</Button>
                    </Uploader>
                </div>
                {mainBannerImage &&
                    <div style={{position: 'relative'}}>
                        <img className='banner-image'
                             src={mainBannerImage}
                             onError={handleImageError}/>
                        <CameraRetroIcon style={{position: 'absolute',fontSize: '36px', color: bannerTextColor,
                            top: '43%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',}}/>
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: '80%',
                            transform: 'translate(-50%, -50%)',
                            color: bannerTextColor,
                        }}
                        >
                            <h6 style={{textAlign: 'center', wordWrap: 'break-word'}}>
                            {bannerText ? bannerText : 'Press Here To Upload Your Photos!'}
                            </h6>
                        </div>
                    </div>}


            </div>
        </div>
    )
}