// Import necessary libraries
import React, {useEffect, useState} from 'react';
import {Form, Button, Notification, Header} from 'rsuite';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import './login.css';
import {api_address} from '../http';
import {FcGoogle} from 'react-icons/fc';

const sendTokenToBackend = async (token) => {

    const response = await fetch('YOUR_BACKEND_ENDPOINT', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({token}),
    });

    if (response.ok) {
        const data = await response.json();
        console.log(data);
        // Handle response data
    } else {
        // Handle error
        console.error('Failed to send token to backend');
    }
};

const responseGoogleFail = (response) => {
    debugger;
    console.log(response);
    // Send the token to your backend
    sendTokenToBackend(response.tokenId);
};

const responseGoogleSuccess = (response) => {
    const {tokenId} = response;
    fetch(`${api_address}/auth/oauth-login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({token: tokenId}),
    })
        .then(res => res.json())
        .then(data => {
            console.log('Login success', data);
        })
        .catch(error => {
            console.error('Login failed', error);
        });
};

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if userData object is in local storage
        const userData = localStorage.getItem('userData');

        // If userData is found, navigate to '/'
        if (userData) {
            navigate('/');
        }
    }, [navigate]); // Run this function when the component mounts

    const handleSubmit = async () => {
        setLoading(true);

        try {
            console.log(email)
            console.log(api_address)
            const response = await axios.post(`${api_address}/auth/login`, {email, password}, {
                withCredentials: true
            });
            if (response.status === 200) {
                // Redirect to homepage if login is successful
                navigate('/');
            } else {

                Notification.error({
                    title: 'Login Failed',
                    description: 'Please check your credentials and try again.',
                });
            }
        } catch (error) {
            Notification.error({
                title: 'Error',
                description: 'An error occurred during login. Please try again later.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='main-login-view'>
            <div className='form-wrapper'>
                <div style={{width:'50%', maxWidth: '200px'}}>
                <img style={{width:"100%", height:'auto'}} src={`${process.env.REACT_APP_CLOUDFRONT_APP_ASSETS}/logo.svg`} alt='App logo'></img>
                </div>
                <div className='login-container'>
                    <Header><h2 style={{marginBottom: '20px'}}>Login</h2></Header>
                    <Form fluid>
                        <Form.Group>
                            <Form.Control
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={value => setEmail(value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                name="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={value => setPassword(value)}
                            />
                        </Form.Group>
                        <Button appearance="primary" block onClick={handleSubmit} loading={loading}>
                            Login
                        </Button>
                    </Form>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className='left-seperator-line'></div>
                        <div className="separator">or</div>
                        <div className='right-seperator-line'></div>
                    </div>
                    <Button
                        // href={`https://${process.env.REACT_APP_GOOGLE_OAUTH_ADDRESS}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_ADDRESS}&response_type=CODE&client_id=2s0jp1ml54fjl8sltnpdi67sn&scope=openid`}
                        href={`https://auth.qr-event.net/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_ADDRESS}&response_type=CODE&client_id=2s0jp1ml54fjl8sltnpdi67sn&scope=openid`}
                        className="google-button" startIcon={<FcGoogle/>}>Sign in with Google</Button>
                </div>
                <div className='other-login-options'>
                    <div style={{marginBottom: '5px'}}>
                        <span>Don't have an account yet? </span>
                        <Link to='/signup'>Sign up</Link>
                    </div>
                    <div>
                        <Link to='/reset-password'>Forgot your password?</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
