import { Outlet } from 'react-router-dom';
import SideNav from './SideNav';
import {useEffect} from "react";
import api, {api_address} from "./http";
import LanguageSwitcher from './LanguageSwitcher'
function RootLayout() {
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await api.get(`${api_address}/users`,
                    {withCredentials: true});
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                const data = response.data;
                localStorage.setItem('userData', JSON.stringify(data));
                // let data = JSON.parse(localStorage.getItem('eventImages'));
            } catch (error) {
                console.error("Error fetching event data:", error);
                // throw error;
            }



        };
        fetchData();
    }, []);
    // useAxiosSetup();
    return (
    <>
    <div className="container">
    <SideNav />
    <div className="content">
        <div style={{display:'flex', justifyContent:'flex-end', width: "90%"}}>
        <LanguageSwitcher />
        </div>
    <Outlet /> 
    </div>
    </div>
    </>)
}

export default RootLayout;