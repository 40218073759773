// import { ReactPhotoCollage } from "react-photo-collage";
import './Gallery.css';
import api, {api_address} from "../../http";
import React, {useEffect, useState} from "react";
import {Loader, Button, Modal} from "rsuite";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import {FaUpload} from "react-icons/fa";
import {FaRegTrashCan} from "react-icons/fa6";
import {useTranslation} from "react-i18next";
import {IoShareOutline} from "react-icons/io5";
import {IoMdDownload} from "react-icons/io";

import PublicGalleryToggle from "./GalleryShareModal";
import EmailForm from "./GalleryDownloadModal";
import InfiniteScroll from "react-infinite-scroll-component";

const MediaDownloadButton = ({url, filename}) => {

        const handleDownload = async (e) => {
            e.stopPropagation();

            try {
                // Fetch the file data
                const response = await fetch(url);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.blob(); // Convert to blob
                const objectUrl = window.URL.createObjectURL(data); // Create an object URL

                // Create an anchor element and trigger download
                const link = document.createElement('a');
                link.href = objectUrl;
                link.download = filename; // Set the filename for the download
                document.body.appendChild(link); // Append to the body temporarily
                link.click(); // Trigger the download

                // Clean up by removing the element and revoking the object URL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error('Download failed:', error);
            }
        };

        return <button
            className="download-button"
            onClick={handleDownload}>
            <IoMdDownload/>
        </button>
    }
;

export default function Gallery({id, GalleryPublic}) {
    const {t} = useTranslation();
    const [eventImages, setEventImages] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deletedItemIds, setDeletedItemIds] = useState(new Set());
    const [showShareModal, setShowShareModal] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [lastId, setLastId] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [moreDataAvailable, setMoreDataAvailable] = useState(true);

    // const [isLoading, setIsLoading] = useState(false);
    const openShareModal = () => setShowShareModal(true);
    const closeShareModal = () => setShowShareModal(false);

    function handleClickDownloadModal() {
        setShowDownloadModal(!showDownloadModal);
    }

    const handleDeleteClick = (e, item) => {
        e.stopPropagation();
        // setMediaToDelete(item);
        // setEventImages(eventImages.filter((i) => i.id !== item.id));
        // setShowModal(true);
        setDeletedItemIds(new Set([...deletedItemIds, item.id]));
    };

    const handleFinalDeleteClick = () => {
        // setEventImages(eventImages.filter((i) => i.id !== item.id));
        setShowModal(true);
    };

    const close = () => {
        setShowModal(false);
    };

    const confirmDelete = () => {
        console.log('deleted');
        setEventImages(eventImages.filter((eventImage) => !deletedItemIds.has(eventImage.id)));
        setShowModal(false);
        const deleteImages = async () => {
            try {
                try {
                    const response = await api.delete(`${api_address}/events/${id}/images`,
                        {
                            data: {
                                deleted_ids: Array.from(deletedItemIds),
                            }, withCredentials: true
                        });
                    if (response.status !== 200) {
                        throw new Error("Network response was not ok");
                    }
                } catch (error) {
                    console.error("Error fetching event data:", error);
                    throw error;
                }

            } catch (error) {
                // Handle errors if needed
            }
        };
        deleteImages();
        setDeletedItemIds(new Set());
    };
    const fetchData = async () => {

        try {
            const response = await api.get(`${api_address}/events/${id}/images`,
                {withCredentials: true,
                    params: {
                        last_id: lastId ?? '',
                    }});
            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            }
            const data = response.data;
            if (firstLoad) {
                setEventImages(data)
                setFirstLoad(false)
            } else {
                setEventImages((prevImages) => [...prevImages, ...data]);
            }
            if (data.length > 0) {
                setLastId(data[data.length - 1].id);
            } else {
                setMoreDataAvailable(false);
            }



        } catch (error) {
            console.error("Error fetching event data:", error);
            throw error;
        }
    };

    useEffect(() => {

        fetchData();
    }, [id]);


    if (eventImages === null) {
        return (
            <div style={{
                display: "flex", justifyContent: 'center', flexDirection: 'column', height: '100vh',
                alignItems: "center",
                width: '100%'
            }}><Loader
                size="lg"/></div>)
    } else if (eventImages.length === 0) {
        return (<div>
            <div style={{textAlign: "right", marginRight: "30px"}}>
                <a href={`/upload?eventId=${id}`} target="_blank"><Button startIcon={<FaUpload/>}>Upload</Button></a>
            </div>
            <div style={{textAlign: "center"}}>
                <h3>No images uploaded yet..</h3>
            </div>
        </div>)
    }


    return (<div>
            <Modal open={showModal} onClose={close}>
                <Modal.Header>
                    <Modal.Title>{t('delete_media_modal_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('delete_media_modal_text')}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={confirmDelete} style={{backgroundColor: '#de5246', color: 'white'}}>{t('yes')}</Button>
                    <Button onClick={close} appearance="subtle">{t('no')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal open={showShareModal} onClose={closeShareModal}>
                <Modal.Header>
                    <Modal.Title>{t('gallery_share_settings_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PublicGalleryToggle eventId={id} GalleryPublic={GalleryPublic}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeShareModal}>{t('close_btn')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal open={showDownloadModal} onClose={handleClickDownloadModal}>
                <Modal.Header>
                    <Modal.Title>{t('download_gallery_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EmailForm eventId={id} closeModalHandler={handleClickDownloadModal}/>
                </Modal.Body>
            </Modal>
            <div className='gallery-buttons-container'>
                <div style={{gap: '10px'}}>
                    <Button onClick={openShareModal} startIcon={<IoShareOutline size={20}/>}
                            appearance="link">{t('share_button')}</Button>
                    <Button onClick={handleClickDownloadModal} startIcon={<IoMdDownload size={20}/>}
                            appearance="link">{t('download_gallery_button')}</Button>
                </div>
                <div style={{textAlign: "right"}}>
                    {deletedItemIds.size > 0 && (
                        <Button startIcon={<FaRegTrashCan/>}
                                style={{backgroundColor: "#de5246", color: 'white', marginRight: "10px"}}
                                onClick={handleFinalDeleteClick}>{t('delete_media_files_button_1')} {deletedItemIds.size} {t('delete_media_files_button_2')}</Button>
                    )}

                    <a href={`/upload?eventId=${id}`} target="_blank"><Button
                        startIcon={<FaUpload/>}>{t('upload_button')}</Button></a>
                </div>
            </div>

            <div className="lightGallery">
                <InfiniteScroll
                    dataLength={eventImages.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={moreDataAvailable}
                    loader={<div style={{height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div><Loader size="lg"/></div></div>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                <LightGallery
                    mode="lg-slide"
                    licenseKey="2E23DB13-6A9B-49AE-9F45-5F3A2D8DB992"
                    speed={500}
                    plugins={[lgZoom, lgVideo, lgThumbnail]}>

                    {eventImages.map((item) => (
                        item.file_type.includes('image') ?
                            <div key={item.id}
                                 className={`gallery-item ${deletedItemIds.has(item.id) ? 'deleted' : 'regular'}`}
                                 href={item.src}
                                 data-src={item.src}
                                 data-sub-html={item.subHtml}>
                                {/*data-lg-size="1406-1390">*/}
                                <FaRegTrashCan className='deleted-icon'/>
                                {/*<div className='buttons-container'>*/}
                                <button
                                    className="trashcan-button"
                                    onClick={(e) => handleDeleteClick(e, item)}>
                                    <FaRegTrashCan/>
                                </button>
                                <MediaDownloadButton filename={item.file_name} url={item.src}/>

                                {/*</div>*/}

                                <img src={item.thumb} alt=""/>

                            </div>
                            :
                            <div key={item.id}
                                 className={`gallery-item ${deletedItemIds.has(item.id) ? 'deleted' : 'regular'}`}
                                 data-video={`{"source": [{"src":"${item.src}", "type":"video/mp4"}], "attributes": {"preload": false, "playsinline": true, "controls": true}}`}
                                 data-sub-html={item.subHtml}>
                                <FaRegTrashCan className='deleted-icon'/>
                                <button
                                    className="trashcan-button"
                                    onClick={(e) => handleDeleteClick(e, item)}>
                                    <FaRegTrashCan/>
                                </button>
                                <MediaDownloadButton filename={item.file_name} url={item.src}/>
                                <img src={item.thumb} alt=""/>

                            </div>
                    ))}

                </LightGallery>
                </InfiniteScroll>
            </div>
        </div>
    );

}