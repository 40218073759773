import React, {useState, useEffect} from 'react';
import {Button, Input, InputGroup, toaster, Notification, Message} from 'rsuite';
import axios from 'axios';
import api from "../../http";
import {IoMdDownload} from "react-icons/io";
import {Form, Schema} from 'rsuite';
import {useTranslation} from "react-i18next";

const { StringType } = Schema.Types;
const model = Schema.Model({
    email: StringType().isEmail('Please enter a valid email address').isRequired('Email is required'),
});

const EmailForm = ({eventId}) => {
    const {t} = useTranslation();
    const [formValue, setFormValue] = useState({ email: '' });
    const [formError, setFormError] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        // const errors = model.check(formValue);
        //
        // setIsFormValid(!errors.hasError);
        // setFormError(errors.errorMessage || {});
        const storedObjectString = localStorage.getItem('userData');

        if (storedObjectString) {
            // If there is a value stored with the key "myObject"
            const parsedObject = JSON.parse(storedObjectString);
            setFormValue({email: parsedObject.email});
            setIsFormValid(true)
        }
    }, []);


    function handleChange(newFormValue) {
        setFormValue(newFormValue);

        const errors = model.check(newFormValue);

        setIsFormValid(!errors.email.hasError);
        // console.log(errors);
        // console.log(isFormValid);
        setFormError(errors.errorMessage || {});
    }

    const handleSubmit = () => {
        const errors = model.check(formValue);
        setIsFormValid(!errors);
        setFormError(errors.errorMessage || {});

        if (!isFormValid) {
            console.error('Form Error');
            return;
        }

        api.post(
            `/events/${eventId}/initialize-gallery-download`,
            { email: formValue.email }, { withCredentials: true }
        )
            .then(response => {
                // handle success response here
            })
            .catch(err => console.error(err));
    };

    return (<div style={{display:'flex',
        flexDirection: 'column',
        gap:'30px', alignItems: 'center'}}>
        <p style={{textAlign:'center'}}>{t('download_gallery_explanation')} </p>
    <div style={{width: '50%', textAlign:'center'}}>
    <Form
        model={model}
        formValue={formValue}
        onCheck={setFormError}
            onChange={handleChange}
            fluid
        >
            <Form.Group>
                <Form.Control name="email" label="Email" type="email" errorMessage={formError.email} />
                {formError.email && <Message showIcon type="error" description={formError.email} />}
            </Form.Group>
            <Form.Group>
                <Button startIcon={<IoMdDownload/>} appearance="primary" onClick={handleSubmit} disabled={!isFormValid}>{t('download_btn')}</Button>
            </Form.Group>
        </Form>
    </div>
    </div>);
};

export default EmailForm;

