
import React, {useEffect} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export const api_address = process.env.REACT_APP_QR_EVENT_API_ADDRESS;
export const app_address = process.env.REACT_APP_QR_EVENT_APP_ADDRESS;
// export const api_address = 'http://10.0.0.8:3636'
const api = axios.create({
    baseURL: `${api_address}`,
});

// Add a response interceptor
// api.interceptors.response.use((response) => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     return response;
// }, async (error) => {
//     const navigate = useNavigate();
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true; // mark it so that we don't get into an infinite loop
//         try {
//             const response = await axios.post(`${api_address}/auth/refresh`,
//                 {}, {withCredentials: true});
//             if (response.status === 200) {
//                 return api(originalRequest);
//             } else {
//                 navigate('/login');
//             }
//         } catch (refreshError) {
//             return Promise.reject(refreshError);
//         }
//     }
//     return Promise.reject(error);
// });




const refreshToken = async () => {
    try {
        const response = await axios.post(`${api_address}/auth/refresh`, {}, {
            withCredentials: true
        });
        return response.status === 200; // True if refresh succeeded, false otherwise
    } catch (error) {
        return false; // Refresh failed
    }
};

api.interceptors.response.use(
    response => response,
    async error => {
        // const navigate = useNavigate();
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshSucceeded = await refreshToken();
            if (refreshSucceeded) {
                return api(originalRequest); // Retry the original request
            } else {
                localStorage.removeItem("userData");
                window.location.replace('/login');
            }
        }
        return Promise.reject(error);
    }
);


// export const useAxiosSetup = () => {
//     const navigate = useNavigate();
//     console.log('interceptor setup')
//     useEffect(() => {
//         const refreshToken = async () => {
//             try {
//                 const response = await axios.post(`${api_address}/auth/refresh`, {}, {
//                     withCredentials: true
//                 });
//                 return response.status === 200; // True if refresh succeeded, false otherwise
//             } catch (error) {
//                 return false; // Refresh failed
//             }
//         };
//         console.log('interceptor effect')
//         debugger;
//         const responseInterceptor = api.interceptors.response.use(
//             response => response,
//             async error => {
//                 debugger;
//                 const originalRequest = error.config;
//                 if (error.response.status === 401 && !originalRequest._retry) {
//                     originalRequest._retry = true;
//                     const refreshSucceeded = await refreshToken();
//                     if (refreshSucceeded) {
//                         return api(originalRequest); // Retry the original request
//                     } else {
//                         navigate('/login'); // Refresh failed, redirect to login
//                     }
//                 }
//                 return Promise.reject(error);
//             }
//         );
//
//         // Cleanup
//         return () => {
//             api.interceptors.response.eject(responseInterceptor);
//         };
//     }, [navigate]); // Dependencies
//
//     // You might return something here, depending on your setup
// };

export default api;
// export const fetchEvents = async (apiInstance) => {
//     try {
//         const response = await apiInstance.get(`${api_address}/events`, {withCredentials: true})
//         if (response.status !== 200) {
//             throw new Error("Network response was not ok");
//         }
//         const eventData = response.data;
//         eventData.forEach(obj => {
//             obj.icon = <ImageIcon/>;
//             obj.eventDetails.date = new Date(obj.eventDetails.date);
//             // obj.eventDetails.date = obj.eventDetails.date.toLocaleDateString('en-GB');
//         });
//         return eventData;
//         // return {eventDetails: {guests: 250, name: 'johns wedding', date: "20/13/2024"}}
//     } catch (error) {
//         console.error("Error fetching event data:", error);
//         throw error;
//     }
// };
//
// export const fetchEventData = async ({id}) => {
//     try {
//         // const response = await fetch("http://localhost:3636/event");
//         const queryParams = new URLSearchParams({
//             id: id
//         });
//
//         const url = `${api_address}/events/details?${queryParams.toString()}`;
//         const response = await api.get(url,
//             {withCredentials: true});
//         if (response.status !== 200) {
//             throw new Error("Network response was not ok");
//         }
//         const eventData = response.data;
//         eventData.eventDetails.date = new Date(eventData.eventDetails.date);
//         // eventData.eventDetails.date = eventData.eventDetails.date.toLocaleDateString('en-GB');
//         return eventData;
//         // return {eventDetails: {guests: 250, name: 'johns wedding', date: "20/13/2024"}}
//     } catch (error) {
//         console.error("Error fetching event data:", error);
//         throw error;
//     }
// };
//
// export const fetchEventImages = async ({eventId}) => {
//     try {
//         const response = await api.get(`${api_address}/events/${eventId}/images`,
//             {withCredentials: true});
//         if (response.status !== 200) {
//             throw new Error("Network response was not ok");
//         }
//         const eventImages = response.data;
//         return eventImages;
//     } catch (error) {
//         console.error("Error fetching event data:", error);
//         throw error;
//     }
// };
//
// // export const updateEventDetails = async (eventId, newFormDetails) => {
// //
// // };

