import './Events.css';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {List, FlexboxGrid, Loader} from 'rsuite';
import ImageIcon from '@rsuite/icons/legacy/Image';
import React, {useEffect, useState} from 'react';
import EventPage from './Event';
import api, {api_address} from "../http";
import { useTranslation } from 'react-i18next';

const styleCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px'
};

const slimText = {
    fontSize: '0.8em',
    color: '#97969B',
    fontWeight: 'lighter',
    paddingBottom: 5
};

const titleStyle = {
    paddingBottom: 5,
    whiteSpace: 'nowrap',
    fontWeight: 500
};

const dataStyle = {
    fontSize: '1.2em',
    fontWeight: 500
};

export default function EventsPage() {
    const renderRaise = React.useCallback(number => {
        const isPositive = number > 0;
        const isNegative = number < 0;
        return (
            <span style={{paddingLeft: 15, color: isNegative ? 'red' : 'green'}}>
        <span>{isPositive ? '+' : null}</span>
        <span>{number}</span>
      </span>
        );
    }, []);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [events, setEvents] = useState(null);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const lang_direction = currentLanguage === 'he' ? 'rtl' : 'ltr';


    // useEffect(() => {
    //     (async () => {
    //         try {
    //             // const data = await fetchEvents(api);
    //             const response = await api.get(`${api_address}/events`, {withCredentials: true})
    //             if (response.status !== 200) {
    //                 throw new Error("Network response was not ok");
    //             }
    //             const eventData = response.data;
    //             eventData.forEach(obj => {
    //                 obj.icon = <ImageIcon/>;
    //                 obj.eventDetails.date = new Date(obj.eventDetails.date);
    //             });
    //             setEvents(eventData);
    //         } catch (error) {
    //            throw error
    //         }
    //     })();
    // }, []);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await api.get(`${api_address}/events`, {withCredentials: true})
                if (response.status !== 200) {
                    throw new Error("Network response was not ok");
                }
                const eventData = response.data;
                eventData.forEach(obj => {
                    obj.icon = <ImageIcon/>;
                    obj.eventDetails.date = new Date(obj.eventDetails.date);
                });
                setEvents(eventData);
            } catch (error) {
                console.error("Error fetching event data:", error);
                throw error;
            }


        };
        fetchData();
    }, []);

    if (events === null) {
        return (<div style={{display: "flex", justifyContent:'center', flexDirection: 'column', height: '100vh',
        width: '100%'}}><Loader size="lg"/></div>)
    }
    // return ( !id ?
    //     <div className="events">
    //         <ul>
    //             {events.map((item) => <Event id={item.id} title={item.title} date={item.date} />)}
    //     </ul>
    //     </div> : <div>Event ID: {id}</div>
    // )
    return (
        !id ? <div style={{width: '100%'}}>
            <section className='event-header'>
                <div className='title'>
                    <h1>{t('events_header')}</h1>
                </div>
            </section>
            <List hover>
                {events.map((item, index) => (
                    <Link to={`/events?id=${item.id}`} style={{color: '#575757', textDecoration:'none'}}>
                        <List.Item key={item['eventDetails']['name']} index={index + 1}>
                            <FlexboxGrid style={{alignItems:'center'}}>
                                {/*icon*/}
                                <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                    {React.cloneElement(item['icon'], {
                                        style: {
                                            color: 'darkgrey',
                                            fontSize: '1.5em'
                                        }
                                    })}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4} style={styleCenter}>
                                {item.demo ? <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <div className='demo-tag'>
                                        <span>{t('demo_tag')}</span>
                                    </div>
                                </div> : null}
                                </FlexboxGrid.Item>
                                {/*base info*/}
                                <FlexboxGrid.Item
                                    colspan={8}
                                    style={{
                                        ...styleCenter,
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div style={titleStyle}>{item['eventDetails']['name']}</div>
                                    <div style={slimText}>

                                        <div>{item['eventDetails']['date'].toLocaleDateString('en-GB')}</div>
                                    </div>
                                </FlexboxGrid.Item>
                                {/*peak data*/}
                                <FlexboxGrid.Item colspan={8} style={styleCenter}>
                                    <div style={{textAlign: 'center'}}>
                                        <div style={slimText}>{t('guests')}</div>
                                        <div style={dataStyle}>{item['eventDetails']['guests']}</div>
                                    </div>
                                    {/* {renderRaise(item['peakRaise'])} */}
                                </FlexboxGrid.Item>
                                {/*uv data*/}

                                {/*uv data*/}

                            </FlexboxGrid>
                        </List.Item>
                    </Link>
                ))}
                <List.Item key='123123' index={323232}>
                    <FlexboxGrid justify='center'>
                        <FlexboxGrid.Item colspan={12} style={styleCenter}>
                            <div style={{textAlign: 'center'}} dir={lang_direction}>
                                <div style={titleStyle}>{t('ready_to_create_your_event')} <a href='https://qr-event.net/pricing'
                                                                                            target='_blank'>{t('click_here')}</a></div>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </div> : <EventPage id={id}/>
    );

}